<template>
  <el-dialog
    title="選擇附加服務"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex flex-col" style="gap: 8px">
      <BaseElInput
        v-model="search.name"
        placeholder="請輸入服務項目名稱"
        clearable
        empty-text="暫無數據"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" />
      </BaseElInput>

      <!-- <div class="flex" style="gap: 8px">
        <BaseElSelect v-model="search.service" clearable placeholder="選擇綁定的服務項目" empty-text="暫無數據">
          <BaseElSelectOption
            v-for="service in categorysData"
            :key="service.id"
            :value="service.id"
            :label="service.name"
          />
        </BaseElSelect>

        <BaseElSelect v-model="search.service" clearable placeholder="選擇綁定的子項目" empty-text="暫無數據">
          <BaseElSelectOption
            v-for="service in categorysData"
            :key="service.id"
            :value="service.id"
            :label="service.name"
          />
        </BaseElSelect>
      </div> -->
    </header>

    <main class="select-area">
      <div class="border-b border-gray-40">
        <p class="select-area-title">選擇服務項目</p>
      </div>
      <div style="padding: 8px">
        <BaseElCheckboxGroup v-model="selected" class="item-group">
          <template v-for="item in chunkData[tableOptions.page - 1]">
            <div :key="item.id" style="padding-left: 15px">
              <BaseElCheckbox :label="item.id" @change="onServiceCheck($event, item)">{{ item.name }}</BaseElCheckbox>
            </div>
          </template>
        </BaseElCheckboxGroup>
      </div>
      <div>
        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="flattenData.length"
        />
      </div>
    </main>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" :disabled="!selected.length" @click="onSubmit">
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed, unref } from 'vue'
import { map, chunk, get, flattenDeep, filter, uniq, some } from 'lodash'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'SubServiceAdvanceSelector',
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    syncData: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 20

    const search = reactive({
      name: null,
      service: null,
    })
    const selected = ref([])
    const activeList = ref([])

    const categorysData = computed(() => {
      return map(props.data, i => ({ id: i.id, name: i.name }))
    })

    const flattenData = computed(() => {
      let data = [...props.data]
      if (search.name) data = filter(data, (i) => i.name.includes(search.name))
      if (search.service) data = filter(data, (i) => i.id === search.service || i.service === search.service)

      return data
    })

    const chunkData = computed(() => chunk(flattenData.value, tableOptions.pageLimit))

    const onSubmit = () => {
      emit('confirm', activeList.value)
      emit('close')
    }

    const onServiceCheck = (check, service) => {
      if (check) {
        activeList.value.push(service)
      } else if (!check) {
        activeList.value = filter(activeList.value, (item) => item.id !== service.id)
      }
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length
      activeList.value = [...props.syncData]
      selected.value = [...map(props.syncData, 'id')]
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      tableDataCount,
      tableOptions,
      flattenData,
      chunkData,
      onServiceCheck,
      categorysData,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .grid-container {
    @apply grid gap-[36px];
    @apply text-normal;
    grid-template-columns: 1fr 75px;
  }

  /* .item-group {
  @apply grid grid-flow-col gap-3;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  > * {
    line-height: 14px;
  }
} */
.item-group  {
    @apply flex flex-col  overflow-y-auto max-h-[500px];
  }

  ::v-deep .el-input {
    @apply w-full;
  }
  ::v-deep .el-select {
    @apply w-full;
  }

  .select-area {
    @apply border border-gray-40 mt-[12px] rounded-[4px];
  }
  .select-area-title {
    @apply p-[8px] text-base font-medium text-sub;
  }

  .divider {
    @apply mt-[8px] mb-[20px];
  }
  </style>
