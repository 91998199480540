<template>
  <el-dialog
    title="選擇服務項目"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex" style="gap: 16px">
      <BaseElInput
        v-model="search.name"
        placeholder="請輸入服務項目名稱"
        empty-text="暫無數據"
        clearable
      >
        <i slot="suffix" class="el-input__icon el-icon-search" />
      </BaseElInput>

      <BaseElSelect v-model="search.category" clearable placeholder="選擇綁定的服務類別" empty-text="暫無數據">
        <BaseElSelectOption
          v-for="category in categorysData"
          :key="category.id"
          :value="category.id"
          :label="category.name"
        />
      </BaseElSelect>
    </header>

    <main class="select-area">
      <div class="border-b border-gray-40">
        <p class="select-area-title">選擇服務項目</p>
      </div>
      <div style="padding: 8px">
        <p class="text-sm text-gray-60">如勾選「服務類別」，日後於該服務類別綁定新的「服務項目」，此範本的通知對象將自動增加新綁定的服務項目</p>
        <el-divider class="divider" />
        <BaseElCheckboxGroup v-model="selected" class="item-group">
          <!-- <div class="flex flex-col flex-wrap" style="max-height: 230px"> -->
          <template v-for="item in chunkData[tableOptions.page - 1]">
            <div v-if="item.type === 'category'" :key="`category-${item.id}`">
              <BaseElCheckbox :label="item.id" @change="onCategoryCheck($event, item)">
                <div>
                  <p class="text-primary-100 underline">服務類別：{{ item.name }}</p>
                </div>
              </BaseElCheckbox>
            </div>
            <div v-if="item.type === 'service'" :key="`service-${item.category}-${item.id}`" style="padding-left: 15px">
              <BaseElCheckbox :label="item.id" class="item" @change="onServiceCheck($event, item)">
                <span class="label">{{ item.name }}</span>
              </BaseElCheckbox>
            </div>
          </template>
          <!-- </div> -->
        </BaseElCheckboxGroup>
      </div>
      <div>
        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="flattenData.length"
        />
      </div>
    </main>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" :disabled="!selected.length" @click="onSubmit">
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed, watch } from 'vue'
import { map, chunk, get, flattenDeep, filter, uniq, some, find, forEach } from 'lodash'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'CategoryServiceAdvanceSelector',
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    syncCompose: { type: Object, default: () => ({}) },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 20

    const search = reactive({
      name: null,
      category: null,
    })
    const selected = ref([])
    const categoryActive = ref([])
    const serviceActive = ref([])
    const serviceDeActive = ref([])

    const composeData = computed(() => {
      return {
        categoryActive: categoryActive.value,
        serviceActive: serviceActive.value,
        serviceDeActive: serviceDeActive.value,
      }
    })

    const categorysData = computed(() => {
      return map(props.data, i => ({ id: i.id, name: i.name }))
    })

    const flattenData = computed(() => {
      let data = props.data.reduce((acc, curr) => {
        acc.push({ id: curr.id, name: curr.name, type: 'category' })
        curr.services.forEach(service => {
          acc.push({ id: service.id, name: service.name, category: curr.id, type: 'service' })
        })
        return acc
      }, [])

      if (search.name) data = filter(data, (i) => i.name.includes(search.name))
      if (search.category) data = filter(data, (i) => i.id === search.category || i.category === search.category)

      return data
    })

    const chunkData = computed(() => chunk(flattenData.value, tableOptions.pageLimit))

    const onSubmit = () => {
      // const services = filter(serviceActive.value, (item) => item.type === 'service')
      emit('confirm', serviceActive.value)
      emit('compose', composeData.value)
      emit('close')
    }

    const onCategoryCheck = (check, category) => {
      const allCategoryServices = filter(flattenData.value, (i) => i.category === category.id)
      if (check) {
        selected.value.push(...map(allCategoryServices, 'id'))
        selected.value = uniq(selected.value)
        categoryActive.value.push(category)
        serviceActive.value.push(...allCategoryServices)
        serviceDeActive.value = filter(serviceDeActive.value, service => !map(allCategoryServices, 'id').includes(service.id))
      } else if (!check) {
        selected.value = filter(selected.value, id => !map(allCategoryServices, 'id').includes(id))
        categoryActive.value = filter(categoryActive.value, item => item.id !== category.id)

        serviceDeActive.value = filter(serviceActive.value, service => !map(allCategoryServices, 'id').includes(service.id))
        serviceActive.value = filter(serviceActive.value, service => !map(allCategoryServices, 'id').includes(service.id))
      }
    }
    const onServiceCheck = (check, service) => {
      if (check) {
        serviceActive.value.push(service)
        serviceDeActive.value = filter(serviceDeActive.value, item => item.id !== service.id)
      } else if (!check) {
        serviceDeActive.value.push(service)
        serviceActive.value = filter(serviceActive.value, item => item.id !== service.id)
      }
    }

    const autoSelect = () => {
      const autoSelectServices = []
      forEach(categoryActive.value, (category) => {
        autoSelectServices.push(...filter(category.services, (service) => !find(serviceDeActive.value, { id: service.id })))
      })
      serviceActive.value.push(...autoSelectServices)
      serviceActive.value = uniq(serviceActive.value)
      return map(autoSelectServices, 'id')
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length

      const { activeCategorys, activeServices, deActiveServices } = { ...props.syncCompose }
      categoryActive.value = activeCategorys
      serviceActive.value = activeServices
      serviceDeActive.value = deActiveServices

      selected.value = [...map(categoryActive.value, 'id'), ...map(serviceActive.value, 'id')]
      selected.value.push(...autoSelect())
      selected.value = uniq(selected.value)
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      tableDataCount,
      tableOptions,
      flattenData,
      chunkData,
      onCategoryCheck,
      onServiceCheck,
      categorysData,
      categoryActive,
      serviceActive,
      serviceDeActive,
      composeData,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .grid-container {
    @apply grid gap-[36px];
    @apply text-normal;
    grid-template-columns: 1fr 75px;
  }
  ::v-deep .el-input {
    @apply w-full;
  }
  ::v-deep .el-select {
    @apply w-full;
  }

  .select-area {
    @apply border border-gray-40 mt-[12px] rounded-[4px];
  }
  .select-area-title {
    @apply p-[8px] text-base font-medium text-sub;
  }

  .divider {
    @apply mt-[8px] mb-[20px];
  }

  /* .item-group {
    @apply grid gap-3 overflow-y-auto max-h-[600px];
    grid-auto-columns: minmax(550px, auto);
    grid-template-rows: repeat(10, minmax(0, 1fr));
    > * {
      line-height: 14px;
    }
  } */

  .item-group  {
    @apply flex flex-col  overflow-y-auto max-h-[500px];
  }

  .item {
    /* @apply max-w-[110px] break-all; */
  }

  </style>
